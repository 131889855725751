import React from "react"
import rehypeReact from "rehype-react"
import { P, H2, H3, H4, H5, H6, Email } from "../components/blocks"

export const renderAst = new rehypeReact({
  createElement: React.createElement,
  components: {
    email: ({ to, domain }) => <Email to={to} domain={domain} />,
    h1: ({ children }) => (
      <div className="bg-primary-200">
        <H2>{children}</H2>
      </div>
    ),
    h2: ({ children }) => <H3>{children}</H3>,
    h3: ({ children }) => <H4>{children}</H4>,
    h4: ({ children }) => <H5>{children}</H5>,
    h5: ({ children }) => <H6>{children}</H6>,
    p: ({ children }) => <P>{children}</P>,
    ul: props => (
      <ul className="tk-list-inside ml-6 list-disc pb-6">{props.children}</ul>
    ),
    ol: props => (
      <ol className="tk-list-inside list-decimal ml-6 pb-6">
        {props.children}
      </ol>
    ),
    li: props => <li className="text-lg leading-relaxed">{props.children}</li>,
    a: props => (
      <a className="text-secondary-500" href={props.href}>
        {props.children}
      </a>
    ),
  },
}).Compiler
